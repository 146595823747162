/* Scrollbars */
html ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

html ::-webkit-scrollbar-track {
    background-color: #f5f5fa;
    border: 1px solid #e6e6eb;
    border-radius: 0;
    padding: 1px;
}

html ::-webkit-scrollbar-thumb {
    background-color: #2d2d32;
    border-radius: 0;
    transition: background-color 0.3s ease;
}

html ::-webkit-scrollbar-thumb:hover {
    background-color: #3d3d42;
}


/* Resets */
body {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.45em;
    text-shadow: none !important;
}

/* Typography */
h1, h2 {
    line-height: 1.25em;
}

h1 {
    font-size: 32px;
    letter-spacing: -0.015em;
    margin-bottom: 16px;
}

h2 {
    font-size: 24px;
    margin-bottom: 16px;
}

p {
}

p.lead {
    font-size: 16px;
    line-height: 1.45em;
}

code {
    font-size: 14px;
    font-family: "Source Code Pro", monospace;
    font-style: normal;
    font-weight: 400;
}

/* Links */
a {
    color: #007FB6;
}

/* Global Nav */
nav.bg-inverse {
    background-color: #333 !important;
}

nav.bg-inverse a:list,
nav.bg-inverse a:visited {
    color: #999;
}

nav.bg-inverse a:hover,
nav.bg-inverse a:active {
    color: #fff;
}

nav .navbar-brand {
    display: flex;
    flex-direction: row;
    align-content: center;
    font-size: 16px;
    font-weight: 300;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
}

nav .strava-subbranding {
    display: flex;
    align-content: center;
    border-left: 1px solid #fff;
    display: inline-block;
    margin-left: 16px;
    padding-left: 16px;
    opacity: 0.6;
}

/* Tables */
td {
    vertical-align: top;
    padding: 2px 8px;
    border: #e0e0e0 1px solid;
}

table.parameters {
    border: none;
}

table.parameters tr {
    border-top: 1px solid #E5E5E5;
}

table.parameters tr:first-child {
    border-top: none;
}

table.parameters td {
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

table.parameters td:first-child {
    text-align: right
}

.parameter-description {
    color: #999;
    font-size: 12px;
}

/* Lists */
.list-links {
    padding-left: 24px;
    list-style: square;
}

.list-links li {
    margin-top: 8px;
    margin-bottom: 8px;
}

/* Buttons */
.btn {
    border-radius: 1px;
    font-size: 14px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-color: #fc4c02;
    border-color: #fc4c02;
}

/* Sections */
.cta-row {
    margin-top: 32px;
    margin-bottom: 32px;
}

.hero {
    background-image: url("./images/apps-hero.jpg");
    background-size: cover;
    color: #fff;
    border-radius: 0;
}

.jumbotron {
    background-color: rgba(0, 0, 0, 0.8);
    margin-bottom: 0;
    border-radius: 0;
}

.jumbotron p {
    max-width: 680px;
    margin-left: auto;
    margin-right: auto;
}

.section {
    padding-top: 32px;
    padding-bottom: 32px;
}

.section-strava-apps {
    padding-top: 0;
    -webkit-font-smoothing: antialiased;
}

.section-strava-apps .btn {
    border: none;
}

.footer {
    margin-top: 32px;
    background-color: #f5f5fa;
}


@media (max-width: 991px) {
    .cta-row .btn {
        margin-bottom: 16px;
    }
}
