/* ------------------------------------------------------------------------------------------
 * Resets
 * ------------------------------------------------------------------------------------------ */
* {
    box-sizing: border-box;
}

body, p, a, div, th, td {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    text-shadow: none !important;
}

hr, p {
    width: 100%
}

img {
    max-width: 100%;
}

/* ------------------------------------------------------------------------------------------
 * Content
 * ------------------------------------------------------------------------------------------ */

/* Global Navbar */
body .navbar {
    position: sticky;
    top: 0;
    z-index: 100;
}

.navbar .container {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.reference {
    position: relative;
    max-width: 100vw;
    z-index: 1;
}

#content {
    padding-left: 236px;
    padding-right: 15px;
    width: 100%;
}

.left-pane,
.right-pane {
    padding-top: 16px;
    padding-bottom: 16px;
}

.right-pane {
    background-color: #2d2d32;
    color: #fff;
}

.right-pane:empty {
    padding-top: 0;
    padding-bottom: 0;
}

.right-pane pre {
    background-color: #3d3d42;
}

.right-pane code {
    font-size: 12px;
    line-height: 16px;
}

.right-pane pre::-webkit-scrollbar-track {
    border: 1px solid #99999e;
    background-color: #99999e;
}

.right-pane pre::-webkit-scrollbar-thumb {
    background-color: #606065;
}

.right-pane pre::-webkit-scrollbar-thumb:hover {
    background-color: #717176;
}


td.code {
    font-size: 14px;
    font-family: "Source Code Pro", monospace;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
}

p {
    color: #808080;
}

h1 {
    font-weight: normal;
    font-size: 44px;
    line-height: 50px;
    margin: 10px 0 10px 0;
    padding: 0;
}

h2 {
    font-weight: normal;
    font-size: 28px;
    line-height: 40px;
    margin: 0 0 20px 0;
    padding: 0;
}

section {
    border-top: 1px solid #ebebeb;
    padding: 30px 0;
}

section h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 14px;
    margin: 0;
    padding: 0;
}

article {
    padding: 14px 0 30px 0;
}

article h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
}

article h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 15px 0 10px 0;
}

article h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 18px;
    margin: 0 0 10px 0;
}

article h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 8px 0;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 0 20px 0;
}

th {
    background-color: #f5f5f5;
    text-align: left;
    font-weight: 700;
    padding: 4px 8px;
    border: #e0e0e0 1px solid;
}

td {
    vertical-align: top;
    padding: 2px 8px;
    border: #e0e0e0 1px solid;
}

#generator .content {
    color: #b0b0b0;
    border-top: 1px solid #ebebeb;
    padding: 10px 0;
}

.label-optional {
    float: right;
}

.open-left {
    right: 0;
    left: auto;
}

/* ------------------------------------------------------------------------------------------
 * pre / code
 * ------------------------------------------------------------------------------------------ */
pre {
    background-color: #333;
    color: #ffffff;
    padding: 10px;
    position: relative;
    margin: 10px 0 20px 0;
    border: none;
}

code.language-text {
    word-wrap: break-word;
}

pre.language-json {
    overflow: auto;
}

pre.language-html {
    margin: 40px 0 20px 0;
}

pre.language-html:before {
    content: attr(data-type);
    position: absolute;
    top: -30px;
    left: 0;
    font-weight: 600;
    font-size: 15px;
    display: inline-block;
    padding: 2px 5px;
    text-transform: uppercase;
    background-color: #3387CC;
    color: #ffffff;
}

pre.language-html[data-type="get"]:before {
    background-color: green;
}

pre.language-html[data-type="put"]:before {
    background-color: #e5c500;
}

pre.language-html[data-type="post"]:before {
    background-color: #4070ec;
}

pre.language-html[data-type="delete"]:before {
    background-color: #ed0039;
}

pre.language-api .str {
    color: #ffffff;
}

pre.language-api .pln,
pre.language-api .pun {
    color: #65B042;
}

pre code {
    display: block;
    font-size: 14px;
    font-family: "Source Code Pro", monospace;
    font-style: normal;
    font-weight: 400;
}

pre code.sample-request-response-json {
    white-space: pre-wrap;
    max-height: 500px;
    overflow: auto;
}

/* ------------------------------------------------------------------------------------------
 * Sidenav
 * ------------------------------------------------------------------------------------------ */

.sidenav {
    width: 220px;
    position: fixed;
    left: 0;
    top: 45px;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
}

.navbar-fixed-side {
    padding: 16px;
}

ul.navbar-nav,
ul.navbar-nav ul {
    list-style: none;
    padding-left: 0;
}

ul.navbar-nav > li {
    border-top: 1px solid #eee;
    padding-top: 16px;
    padding-bottom: 16px;
}

ul.navbar-nav > li:first-child {
    border-top: none;
    padding-top: 0;
}

ul.navbar-nav a {
    font-size: 14px;
}

ul.navbar-nav ul {
    margin-top: 8px;
}

ul.navbar-nav ul a {
    font-size: 12px;
}

.dropdown-toggle::after {
    content: "";
    display: none;
}

.navbar-fixed-side .navbar-nav .dropdown-menu > li > a {
    color: black;
}

.nav-pills .nav-link {
    border-radius: 0;
    color: #F5F5F5;
    margin-left: 2px;
    transition: background-color 0.15s ease;
}

.nav-pills .nav-link.active,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
    color: #ffffff;
    background-color: #fc4c02;
}

.nav-pills > li > a:hover {
    background-color: #606065;
}

.right-pane h4 {
    color: #F5F5F5;
    font-size: 18px;
}

.http {
    margin: 14px 0 14px 0;
}

pre.http-method {
    float: left;
    margin: 0 5px 0 0;
    text-transform: uppercase;
    border-radius: .25rem;
}

.http-method-get {
    background-color: #2DAA35;
}

.http-method-delete {
    background-color: #FC1A02;
}

.http-method-put {
    background-color: #FCB802;
}

.http-method-post {
    background-color: #21B5EA;
}

pre.http-request-line {
    margin: 0;
    border-radius: .25rem;
}

.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

